import React from "react";
import { VscClose } from "react-icons/vsc";

const Modal = ({ show, onClose, children, title, size, borderTitle, scIn = true, overflow }) => {
    if (!show) {
        return null;
    }
    return (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50 fd-in">
            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

            <div
                className={`${size ? size : ""} modal-container bg-white mx-auto  rounded shadow-lg z-50 max-h-80vh ${overflow ? overflow : "overflow-y-auto"} ${scIn ? "sc-in" : ""}`}>
                <div className="modal-content py-2 text-left px-5 h-auto">
                    <div
                        className={`flex justify-between items-center pb-2 ${borderTitle ? "border-b" : ""} border-C2 mb-4`}
                    >
                        <div>{title}</div>
                        <div className="modal-close cursor-pointer z-50 -mr-2" onClick={onClose}>
                            <VscClose className="text-C7" size="2rem" stroke="0" />
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
