import React, { useState } from "react";
import { IoIosUndo, IoIosSend } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

import Modal from "../../../components/Modals";
import api from "../../../services/api";

const ModalReturn = ({ actionModal, openModal, entity, handleSuccess, setLoadingChangeStatus }) => {
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-4/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>
                        Devolver cotação {entity?.name} para análise da logística
                    </h5>
                </div>
            }
        >
            <label htmlFor="description" className="w-full">
                Motivo:
            </label>
            <input
                type="text"
                className="w-full rounded-md border py-1 px-5 border-C2 h-9"
                value={description}
                onChange={e => {
                    setDescription(e.target.value);
                }}
            />
            {descriptionError && <p className="text-red w-full text-sm">{descriptionError}</p>}

            <button
                className="mb-4 ml-2 bg-C4 text-white py-2 px-5 rounded-md float-left mt-10 flex items-center"
                onClick={() => {
                    actionModal();
                    setLoadingChangeStatus(false);
                }}
            >
                <IoIosUndo size={"1em"} />
                <span className="text-lg ml-1">Voltar</span>
            </button>
            <button
                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10 flex items-center"
                onClick={() => {
                    if (description.length === 0) {
                        setDescriptionError("O motivo é obrigatório");
                        return;
                    }

                    setLoading(true);

                    api.post(
                        "quote/" + entity?.identifier + "/change-status",
                        {
                            status: "devoluted_as_legal",
                            description: description,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                            },
                        }
                    ).then(response => {
                        setLoading(false);
                        setLoadingChangeStatus(false);
                        toast.success("Cotação devolvida com sucesso para análise da logística");
                        handleSuccess();
                    });
                }}
            >
                <IoIosSend size={"1em"} />
                <span className="text-lg ml-1">Enviar {loading && <ClipLoader size="13" color="white" />}</span>
            </button>
        </Modal>
    );
};
export default ModalReturn;
